
<template>
    <div>
        <loading v-show="pageLoading"/>
        <div v-show="!pageLoading">
            <div v-show="formProcess === 'emailSend'">
                <ValidationObserver ref="formEmailSend">
                    <ValidationProvider name="email" rules="required|email" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('email')">
                            <b-form-input v-model="email" type="email" :state="errors[0] ? false : null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                    <div class="d-flex justify-content-center">
                        <b-button variant="primary" @click="emailSend" :disabled="formLoading">
                            {{ $t('send') }}
                        </b-button>
                    </div>
                </ValidationObserver>
            </div>
            <div v-show="formProcess === 'emailVerify'">
                <b-form-group :label="$t('email')">
                    <div class="label-as-input">{{ email }}</div>
                </b-form-group>
                <div class="mb-3">
                    <ValidationObserver ref="formEmailVerify">
                        <ValidationProvider name="verification_code" rules="required" v-slot="{ valid, errors }">
                            <sms-input v-model="emailCode"
                                       :timerFrom="emailTimer"
                                       @timerExpired="timerExpired"/>
                        </ValidationProvider>
                    </ValidationObserver>
                </div>
                <b-row>
                    <b-col>
                        <b-button type="button"
                                  variant="primary"
                                  size="lg"
                                  block
                                  @click="emailVerify"
                                  :disabled="emailTimer === 0">
                            {{ $t('verify').toUpperCase() }}
                        </b-button>
                    </b-col>
                    <b-col>
                        <b-button type="button"
                                  variant="outline-secondary"
                                  size="lg"
                                  block
                                  @click="emailSend"
                                  :disabled="emailTimer > 0">
                            {{ $t('send_again').toUpperCase() }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </div>

    </div>
</template>

<script>
    // Components
    import Loading from '@/components/elements/Loading.vue';
    import SmsInput from '@/components/elements/SmsInput.vue';
    import {mapGetters} from "vuex";


    // Services
    import roomReservationSystemService from '@/services/roomReservationSystemService'

    // Other
    import { ValidationObserver, ValidationProvider } from 'vee-validate'

    export default {
        components: {
            Loading,
            SmsInput,
            ValidationProvider,
            ValidationObserver,
        },
        props: {
            emailAddress: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                // Page
                pageLoading: false,

                // Form
                formLoading: false,
                formProcess: 'emailSend',

                // Form data
                email: this.emailAddress,
                emailTimer: 0,
                emailCode: '',
                copyData: {}
            }
        },
        computed: {
            ...mapGetters({
            formData: "housingReservationForm/formData"
            })
        },
        methods: {
            startTimer() {
                if (this.timerInterval) {
                    clearInterval(this.timerInterval);
                }

                this.emailTimer = 60;

            },
            async emailSend() {
                const isValid = await this.$refs.formEmailSend.validate();
                const isValid2 = await this.$refs.formEmailVerify.validate();
                if (isValid || isValid2) {
                    const formData = { email: this.email };
                    try {
                        const response = await roomReservationSystemService.sendEmailCode(formData);
                        const data = response.data.data;
                        this.emailTimer = data.expires_in;
                        this.startTimer();
                        this.formProcess = 'emailVerify';
                        this.$emit('emailUpdated', this.email);

                        this.copyData = this.formData
                        this.copyData.email = this.email
                        delete this.copyData.name
                        const newData = {
                            ...this.copyData,

                        }

                       await  this.$store.dispatch('housingReservationForm/deleteFormData')

                        this.$store.dispatch('housingReservationForm/saveFormData', newData);

                    } catch (e) {
                        this.showErrors(e, this.$refs.formEmailSend);
                    }
                }
            },

            timerExpired() {
                this.emailTimer = 0;
                clearInterval(this.timerInterval);
            },

            async emailVerify() {
                const isValid = await this.$refs.formEmailVerify.validate();
                if (isValid) {
                    const formData = {
                        verification_code: this.emailCode,
                        email: this.email
                    };
                    this.formLoading = true;
                    try {
                        const response = await roomReservationSystemService.verifyEmailCode(formData);
                        const message = response.data.message;
                        this.$toast.success(this.$t('api.' + message));
                        this.$emit('verificationSuccess');
                        this.$emit('emailUpdated', this.email);

                    } catch (e) {
                        this.showErrors(e, this.$refs.formEmailVerify);
                    } finally {
                        this.formLoading = false;
                    }
                }
            }
        }
    }
</script>
